<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :src="barImage"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="240"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="font-weight-regular display-2">
          <router-link
            to="/"
            class="text-decoration-none"
          >
            <v-avatar
              size="50"
              rounded="0"
            >
              <img :src="imgLogo">
            </v-avatar>
            <span class="white--text"> - Ashia Admin</span>
          </router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="mb-1" />
    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
      <template v-if="isFrogAdmin">
        <template v-for="(item, i) in computedItems">
          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
            <!--  -->
          </base-item-group>

          <base-item
            v-else
            :key="`item-${i}`"
            :item="item"
          />
        </template>
      </template>
      <template v-if="!isFrogAdmin">
        <template v-for="(item, i) in computedItems">
          <base-item
            v-if="item.require !== 'FrogAdmin'"
            :key="`item-${i}`"
            :item="item"
          />
        </template>
      </template>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import {
  mapState
} from 'vuex'

export default {
  name: 'DashboardCoreDrawer',

  props: {
    imgLogo: {
      type: String,
      default: 'https://frogv3.s3.eu-west-2.amazonaws.com/website/ashia/images/Ashia-logo.svg'
    }
  },

  data: () => ({
    userID: 1,
    items: [
      {
        icon: 'mdi-view-dashboard-outline',
        title: 'Insights',
        to: '/'
      },
      {
        icon: 'mdi-bullseye',
        title: 'Portals',
        to: '/portal-management'
      },
      {
        icon: 'mdi-application-export',
        title: 'Data Exports',
        to: '/exports'
      },
      {
        icon: 'mdi-file-chart-outline',
        title: 'Reports',
        to: '/reports'
      },
      {
        group: '/marketing',
        require: 'FrogAdmin',
        icon: 'mdi-book-open-page-variant-outline',
        title: 'Marketing',
        children: [
          {
            title: 'Broadcast',
            icon: 'mdi-broadcast',
            to: 'broadcast'
          },
          {
            title: 'Catalogue',
            icon: 'mdi-folder-multiple',
            to: 'catalogue'
          }
        ]
      },
      {
        group: '/manage',
        require: 'FrogAdmin',
        icon: 'mdi-database',
        title: 'Manage',
        children: [
          {
            title: 'Groups',
            icon: 'mdi-file-table-box-multiple-outline',
            to: 'groups'
          },
          {
            title: 'Listings',
            icon: 'mdi-clipboard',
            to: 'listings'
          },
          {
            title: 'Videos',
            icon: 'mdi-play-box-multiple-outline',
            to: 'videos'
          },
          {
            title: 'Resources',
            icon: 'mdi-folder-outline',
            to: 'resources'
          },
          {
            title: 'Marketing',
            icon: 'mdi-book-open-page-variant-outline',
            to: 'marketing'
          },
          {
            title: 'Tags',
            icon: 'mdi-tag-multiple-outline',
            to: 'tags'
          }
        ]
      }
    ]
  }),

  computed: {
    ...mapState(['barColor', 'barImage']),
    drawer: {
      get () {
        return this.$store.state.drawer
      },
      set (val) {
        this.$store.commit('SET_DRAWER', val)
      }
    },
    isFrogAdmin () {
      const userRole = []
      this.$auth.user['https://userrolesfrog.admin/user_authorization'].roles.forEach(element => {
        userRole.push(element)
      })
      if (userRole.includes('Frog Admin')) {
        return true
      } else {
        return false
      }
    },
    computedItems () {
      return this.items.map(this.mapItem)
    }
  },

  watch: {
    '$vuetify.breakpoint.smAndDown' (val) {
      this.$emit('update:expandOnHover', !val)
    }
  },

  methods: {
    mapItem (item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title)
      }
    }
  }
}
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
